import a308 from "./img/aretes/A308.png"
import a312 from "./img/aretes/A312.png"
import a312p from "./img/aretes/A312_.png"
import a315 from "./img/aretes/A315.png"
import a317 from "./img/aretes/A317.png"
import a320 from "./img/aretes/A320.png"
import a321 from "./img/aretes/A321.png"
import a326 from "./img/aretes/A326.png"
import a327 from "./img/aretes/A327.png"
import a328 from "./img/aretes/A328.png"
import a329 from "./img/aretes/A329.png"
import am001 from "./img/arosMatrimonio/AM001.png"
import am002 from "./img/arosMatrimonio/AM002.png"
import am003 from "./img/arosMatrimonio/AM003.png"
import am004 from "./img/arosMatrimonio/AM004.png"
import d407 from "./img/dijes/D407.png"
import d426 from "./img/dijes/D426.png"
import d428 from "./img/dijes/D428.png"
import d429 from "./img/dijes/D429.png"
import d430 from "./img/dijes/D430.png"
import d427 from "./img/dijes/D427.png"
import a1508 from "./img/anillosCompromiso/diamante/1508.png"
import a1801 from "./img/anillosCompromiso/diamantes/1801.png"
import a2009 from "./img/anillosCompromiso/diamante/2009.png"
import a2503 from "./img/anillosCompromiso/diamante/2503.png"
import a3009 from "./img/anillosCompromiso/diamante/3009.png"
import a3010 from "./img/anillosCompromiso/diamante/3010.png"
import a3102 from "./img/anillosCompromiso/diamantes/3102.png"
import a3107 from "./img/anillosCompromiso/diamantes/3107.png"

import a4002 from "./img/anillosCompromiso/diamante/4002.png"
import a4011 from "./img/anillosCompromiso/diamante/4011.png"
import a4012 from "./img/anillosCompromiso/diamante/4012.png"
import a4013 from "./img/anillosCompromiso/diamante/4013.png"
import a4014 from "./img/anillosCompromiso/diamante/4014.png"
import a4021 from "./img/anillosCompromiso/diamante/4021.png"
import a4022 from "./img/anillosCompromiso/diamante/4022.png"
import a4023 from "./img/anillosCompromiso/diamante/4023.png"
import a4024 from "./img/anillosCompromiso/diamante/4024.png"
import a4025 from "./img/anillosCompromiso/diamante/4025.png"
import a4026 from "./img/anillosCompromiso/diamantes/4026.png"
import a4027 from "./img/anillosCompromiso/diamantes/4027.png"
import a4028 from "./img/anillosCompromiso/diamantes/4028.png"
import a4029 from "./img/anillosCompromiso/diamantes/4029.png"
import a4030 from "./img/anillosCompromiso/diamantes/4030.png"
import a4032 from "./img/anillosCompromiso/diamantes/4032.png"
import a5007 from "./img/anillosCompromiso/diamantes/5007.png"
import a5014 from "./img/anillosCompromiso/diamantes/5014.png"
import s205 from "./img/anillosTodaOcasion/S205.png"
import s217 from "./img/anillosTodaOcasion/S217.png"
import s218 from "./img/anillosTodaOcasion/S218.png"
import s221 from "./img/anillosTodaOcasion/S221.png"
import s223__ from "./img/anillosTodaOcasion/S223__.png"
import s223_ from "./img/anillosTodaOcasion/S223_.png"
import s223 from "./img/anillosTodaOcasion/S223.png"
import s226 from "./img/anillosTodaOcasion/S226.png"
import s227 from "./img/anillosTodaOcasion/S227.png"

import a5011 from "./img/anillosCompromiso/diamantes/5011.png";
import a3101 from "./img/anillosCompromiso/diamantes/3101.png"
import a4031 from "./img/anillosCompromiso/diamantes/4031.png"


export default [{
        "CÓDIGO": "A318",
        "DESCRIPCIÓN": "Aretes modelo rosetta confeccionados en oro blanco de 18k con un diamante central y cinco pétalos de diamantes, todos de corte excelente.                                                                                                                                   (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Arete",
        "Sección": "Joyas",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante(Redondo)",
        "Otras Gemas": "X",
        "imagen": a317,
    },
    {
        "CÓDIGO": "A320",
        "DESCRIPCIÓN": "Aretes modelo media bola, clásicos, confeccionados en oro amarillo de 18k.                                                                                                       (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Arete",
        "Sección": "Joyas",
        "Color": "Oro-Amarillo",
        "TonalidadDeDiamante": "X",
        "FormaDeDiamante": "X",
        "Otras Gemas": "X",
        "imagen": a320,
    },
    {
        id: 2,
        "CÓDIGO": "A321",
        "DESCRIPCIÓN": "Aretes modelo solitario confeccionados en oro amarillo de 18k con dos diamantes centrales engastados en uñas, todos de corte excelente.                                                                                                          (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Arete",
        "Sección": "Joyas",
        "Color": "Oro-Amarillo",
        "TonalidadDeDiamante": "X",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": a321,
    },
    {
        "CÓDIGO": "A312",
        "DESCRIPCIÓN": "Aretes modelo estrella poste confeccionados en oro blanco de 18k con ciento sesenta diamantes engastados al paveé , todos de corte excelente.                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Arete",
        "Sección": "Joyas",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": a312,
    },
    {
        "CÓDIGO": "A317",
        "DESCRIPCIÓN": "Aretes modelo rosetta confeccionados en oro amarillo de 18k con un diamante central y cinco pétalos de diamantes, todos de corte excelente.                                                                                                                                   (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Arete",
        "Sección": "Joyas",
        "Color": "Oro-Amarillo",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": a312p,
    },
    {
        "CÓDIGO": "A326",
        "DESCRIPCIÓN": "Aretes modelo rosetta redonda confeccionados en oro blanco de 18k con catorce diamantes engastados en uñas, todos de corte excelente.                                                                                                                  (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Arete",
        "Sección": "Joyas",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": a326,
    },
    {
        "CÓDIGO": "A327",
        "DESCRIPCIÓN": "Aretes Amatista confeccionados en oro amarillo de 18k con dos amatistas pear shape acompañadas de diamantes amarillo canario engastados en uñas, todos de corte excelente.                                                                                              (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Arete",
        "Sección": "Joyas",
        "Color": "Oro-Amarillo",
        "TonalidadDeDiamante": "Blanco y Amarillo Canario",
        "FormaDeDiamante": "Brillante(Redondo) y Pera",
        "OtrasGemas": "Amatista",
        "imagen": a327,
    },
    {
        "CÓDIGO": "A315",
        "DESCRIPCIÓN": "Aretes modelo gotas de diamante, confeccionados en oro blanco de 18k con doscientos cincuenta diamantes engastados al paveé , todos de corte excelente.                                                                                                                   (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Arete",
        "Sección": "Joyas",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": a315,
    },
    {
        "CÓDIGO": "A328",
        "DESCRIPCIÓN": "Aretes modelo doble gota, confeccionados en oro blanco de 18k con ciento catorce diamantes engastados en uñas , todos de corte excelente.                                                                                                                  (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Arete",
        "Sección": "Joyas",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": a328,
    },
    {
        "CÓDIGO": "A308",
        "DESCRIPCIÓN": "Aretes modelo estrella de mar, confeccionados en oro blanco de 18k con ochenta y dos diamantes engastados en uñas , todos de corte excelente.                                                                                                                  (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Arete",
        "Sección": "Joyas",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": a308,
    },
    {
        "CÓDIGO": "A329",
        "DESCRIPCIÓN": "Aretes modelo florcita, confeccionados en oro amarillo de 18k con dos diamantes centrales engastados al bisel , todos de corte excelente.                                                                                                                  (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Arete",
        "Sección": "Joyas",
        "Color": "Oro-Amarillo",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": a329,
    },
    {
        "CÓDIGO": "D426",
        "DESCRIPCIÓN": "Dije Modelo Lazo confeccionado en oro amarillo con detalles de oro blanco de 18kcon tres diamantes engastados al tope, todos de corte excelente.                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Dije",
        "Sección": "Joyas",
        "Color": "Oro-Amarillo-y-Oro Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": d426,
    },
    {
        "CÓDIGO": "D427",
        "DESCRIPCIÓN": "Dije Modelo Hoja confeccionado en oro blanco  de 18kcon setenta y dos diamantes engastados en uñas, todos de corte excelente.                                                                                                                                                            (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Dije",
        "Sección": "Joyas",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": d427,
    },
    {
        "CÓDIGO": "D428",
        "DESCRIPCIÓN": "Dije Modelo Solitario confeccionado en oro blanco de 18k con un diamante central engastado en bisel con diseño, de corte excelente.                                                                                                                                                                (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Dije",
        "Sección": "Joyas",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": d428,
    },
    {
        "CÓDIGO": "D407",
        "DESCRIPCIÓN": "Dije Modelo óvalo doble confeccionado en oro blanco de 18k con ciento once diamantes engastado en uñas, todos de corte excelente.                                                                                                                                                   (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Dije",
        "Sección": "Joyas",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": d407,
    },
    {
        "CÓDIGO": "D429",
        "DESCRIPCIÓN": "Dije Modelo Gota confeccionado en oro blanco de 18k con un diamante central pear shape   acompañado por quince diamantes engastados en uñas, todos de corte excelente.                                                                                                                                (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Dije",
        "Sección": "Joyas",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante(Redondo) y Pera",
        "Otras Gemas": "X",
        "imagen": d429,
    },
    {
        "CÓDIGO": "D430",
        "DESCRIPCIÓN": "Dije Corazón azul confeccionado en oro blanco de 18k con un diamante central azul engastado al tope,  de corte excelente.                                                                                                                                                                                   (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Dije",
        "Sección": "Joyas",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Azul",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": d430,
    },
    {
        "CÓDIGO": "S217",
        "DESCRIPCIÓN": "Sortija Modelo Rosita confeccionado en oro amarillo de  18kcon un diamante central engastado al bisel y once diamantes alrededor engastados en uñas, todos de corte excelente.                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Sortija-toda-ocasión",
        "Sección": "Joyas",
        "Color": "Oro-Amarillo-y-Oro Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": s217,
    },
    {
        "CÓDIGO": "S223",
        "DESCRIPCIÓN": "Sortija Modelo Medio Riviere confeccionado en oro amarillo de  18kcon trece diamantes engastados en uñas, todos de corte excelente.                                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Sortija-toda-ocasión",
        "Sección": "Joyas",
        "Color": "Oro-Amarillo",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": s223,
    },
    {
        "CÓDIGO": "S225",
        "DESCRIPCIÓN": "Sortija Modelo florcita confeccionado en oro amarillo de  18kcon dos diamantes engastados al bisel, todos de corte excelente.                                                                                                                                                                           (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Sortija-toda-ocasión",
        "Sección": "Joyas",
        "Color": "Oro-Amarillo",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": s223_,
    },
    {
        "CÓDIGO": "S218",
        "DESCRIPCIÓN": "Sortija Modelo Rosita confeccionado en oro blanco de  18kcon un diamante central engastado al bisel y once diamantes alrededor engastados en uñas, todos de corte excelente.                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Sortija-toda-ocasión",
        "Sección": "Joyas",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": s218,
    },
    {
        "CÓDIGO": "S227",
        "DESCRIPCIÓN": "Sortija Modelo Aro blue & white confeccionado en oro blanco de  18kcon trece diamantes azules y blancos engastados en uñas, todos de corte excelente.                                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Sortija-toda-ocasión",
        "Sección": "Joyas",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco y  Azul",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": s227,
    },
    {
        "CÓDIGO": "S226",
        "DESCRIPCIÓN": "Sortija Modelo medio riviere blue & white confeccionado en oro blanco de  18kcon nueve diamantes azules y blancos engastados en uñas, todos de corte excelente.                                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Sortija-toda-ocasión",
        "Sección": "Joyas",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco y  Azul",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": s226,
    },
    {
        "CÓDIGO": "S224",
        "DESCRIPCIÓN": "Sortija Modelo Medio Riviere banda delgada confeccionado en oro blanco de  18kcon veinticinco diamantes engastados en uñas, todos de corte excelente.                                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Sortija-toda-ocasión",
        "Sección": "Joyas",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": s223__,
    },
    {
        "CÓDIGO": "S205",
        "DESCRIPCIÓN": "Sortija Modelo Medio Riviere confeccionado en oro blanco de  18kcon trece diamantes engastados al grano con biselado, todos de corte excelente.                                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Sortija-toda-ocasión",
        "Sección": "Joyas",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": s205,
    },
    {
        "CÓDIGO": "S221",
        "DESCRIPCIÓN": "Sortija Modelo Rosetta confeccionado en oro blanco de  18kcon siete diamantes engastados en uñas,  todos de corte excelente.                                                                                                                                                                (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Sortija-toda-ocasión",
        "Sección": "Joyas",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "X",
        "imagen": s221,
    },
    {
        "CÓDIGO": "AM001",
        "DESCRIPCIÓN": "Aros de matrimonio clásicos media caña confeccionados en oro amarillo de 18k                                                                                                                                    (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Aros de matrimonio sin diamantes",
        "Sección": "Novios / Matrimonio",
        "Color": "Oro-Amarillo",
        "TonalidadDeDiamante": "X",
        "FormaDeDiamante": "X",
        "Otras Gemas": "X",
        "imagen": am001,
    },
    {
        "CÓDIGO": "AM002",
        "DESCRIPCIÓN": "Aros de matrimonio clásicos media caña confeccionados en oro blanco de 18k                                                                                                                                    (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Aros de matrimonio sin diamantes",
        "Sección": "Novios / Matrimonio",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "X",
        "FormaDeDiamante": "X",
        "Otras Gemas": "X",
        "imagen": am002,
    },
    {
        "CÓDIGO": "AM003",
        "DESCRIPCIÓN": "Aros de matrimonio  media caña con diseño corte hoja, confeccionados en oro amarillo de 18k  con tres diamantes engastados al grano, todos de corte excelente.                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Aros de matrimonio con diamantes",
        "Sección": "Novios / Matrimonio",
        "Color": "Oro-Amarillo",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": am003,
    },
    {
        "CÓDIGO": "AM004",
        "DESCRIPCIÓN": "Aros de matrimonio  media caña con  diseño corte hoja, confeccionados en oro blanco de 18k  con tres diamantes engastados al grano, todos de corte excelente.                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Aros de matrimonio con diamantes",
        "Sección": "Novios / Matrimonio",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": am004,
    },
    {
        "CÓDIGO": "3009",
        "DESCRIPCIÓN": "Anillo de compromiso solitario confeccionado en oro amarillo y oro blanco de 18k.  Se recomienda colocar diamantes desde 0,30 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Solitario",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro Amarillo y Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a3009,
    },
    {
        "CÓDIGO": "4021",
        "DESCRIPCIÓN": "Anillo de compromiso solitario confeccionado en oro amarillo  de 18k.  Se recomienda colocar diamantes desde 0,20 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Solitario",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Amarillo",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a4021,
    },
    {
        "CÓDIGO": "2503",
        "DESCRIPCIÓN": "Anillo de compromiso solitario confeccionado en oro amarillo  de 18k.  Se recomienda colocar diamantes desde 0,20 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Solitario",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Amarillo",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a2503,
    },
    {
        "CÓDIGO": "2009",
        "DESCRIPCIÓN": "Anillo de compromiso solitario confeccionado en oro amarillo  de 18k. Se recomienda colocar diamantes desde 0,18 Carats. Diamantes de corte excelente y certificación GIA.(Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Solitario",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Amarillo",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a2009,
    },
    {
        "CÓDIGO": "4011",
        "DESCRIPCIÓN": "Anillo de compromiso solitario confeccionado en oro amarillo  de 18k.  Se recomienda colocar diamantes desde 0,23 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Solitario",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Amarillo",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a4011,
    },
    {
        "CÓDIGO": "4023",
        "DESCRIPCIÓN": "Anillo de compromiso solitario confeccionado en oro amarillo  de 18k con un Diamante central Heart Shape.  Se recomienda colocar diamantes desde 0,40 Carats. Diamantes de corte excelente y certificación GIA. (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Solitario",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Amarillo",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Corazón",
        "Otras Gemas": "",
        "imagen": a4023,
    },
    {
        "CÓDIGO": "4025",
        "DESCRIPCIÓN": "Anillo de compromiso solitario confeccionado en oro amarillo  de 18k.  Se recomienda colocar diamantes desde 0,30 Carats. Diamantes de corte excelente y certificación GIA. (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Solitario",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Amarillo",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a4025,
    },
    {
        "CÓDIGO": "4013",
        "DESCRIPCIÓN": "Anillo de compromiso solitario confeccionado en oro amarillo  de 18k.  Se recomienda colocar diamantes desde 0,30 Carats. Diamantes de corte excelente y certificación GIA.  (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Solitario",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro Amarillo y Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a4013,
    },
    {
        "CÓDIGO": "3010",
        "DESCRIPCIÓN": "Anillo de compromiso solitario confeccionado en oro blanco de 18k.  Se recomienda colocar diamantes desde 0,30 Carats. Diamantes de corte excelente y certificación GIA.  (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Solitario",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a3010,
    },
    {
        "CÓDIGO": "1508",
        "DESCRIPCIÓN": "Anillo de compromiso solitario confeccionado en oro blanco de 18k.  Se recomienda colocar diamantes desde 0,20 Carats. Diamantes de corte excelente y certificación GIA. (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Solitario",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a1508,
    },
    {
        "CÓDIGO": "4022",
        "DESCRIPCIÓN": "Anillo de compromiso solitario confeccionado en oro blanco de 18k.  Se recomienda colocar diamantes desde 0,30 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Solitario",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a4022,
    },
    {
        "CÓDIGO": "4014",
        "DESCRIPCIÓN": "Anillo de compromiso solitario confeccionado en oro blanco  de 18k.  Se recomienda colocar diamantes desde 0,30 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Solitario",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a4014,
    },
    {
        "CÓDIGO": "4002",
        "DESCRIPCIÓN": "Anillo de compromiso solitario confeccionado en oro amarillo  de 18k.  Se recomienda colocar diamantes desde 0,35 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Solitario",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a4002,
    },
    {
        "CÓDIGO": "4012",
        "DESCRIPCIÓN": "Anillo de compromiso solitario confeccionado en oro amarillo  de 18k.  Se recomienda colocar diamantes desde 0,23 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Solitario",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a4012,
    },
    {
        "CÓDIGO": "4024",
        "DESCRIPCIÓN": "Anillo de compromiso solitario confeccionado en oro amarillo  de 18k.  Se recomienda colocar diamantes desde 0,20 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Solitario",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a4024,
    },
    {
        "CÓDIGO": "4026",
        "DESCRIPCIÓN": "Anillo de compromiso Modelo Pure Elegance  confeccionado en oro blanco con detalles de oro amarillo de 18k.  La pieza cuenta con un total de veinte diamantes. Se recomienda colocar un diamante central desde 0,40 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Con más Diamantes ó Laterales",
        "novios":"diamantes",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro  Blanco y Amarillo",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a4026,
    },
    {
        "CÓDIGO": "4028",
        "DESCRIPCIÓN": "Anillo de compromiso  confeccionado en oro blanco de 18k.  La pieza cuenta con un Diamante central azul acompañado por veinticuatro diamantes blancos. Se recomienda colocar un diamante central desde 0,40 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Con más Diamantes ó Laterales",
        "novios":"diamantes",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Azul y Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a4028,
    },
    {
        "CÓDIGO": "4030",
        "DESCRIPCIÓN": "Anillo de compromiso Modelo Petite Elegance  confeccionado en oro blanco con detalles de oro amarillo de 18k.  La pieza cuenta con un total de once diamantes. Se recomienda colocar un diamante central desde 0,30 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                 (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Con más Diamantes ó Laterales",
        "novios":"diamantes",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro  Blanco y Amarillo",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a4030,
    },
    {
        "CÓDIGO": " 4032",
        "DESCRIPCIÓN": "Anillo de compromiso  confeccionado en oro blanco de 18k.  La pieza cuenta con un total de cincuenta y cinco diamantes. Se recomienda colocar un diamante central desde 0,40 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                               (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Con más Diamantes ó Laterales",
        "novios":"diamantes",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a4032,
    },
    {
        "CÓDIGO": " 5014",
        "DESCRIPCIÓN": "Anillo de compromiso  confeccionado en oro blanco de 18k.  La pieza cuenta con un total de veinticinco diamantes. Se recomienda colocar un diamante central desde 0,30 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                               (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Con más Diamantes ó Laterales",
        "novios":"diamantes",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a5014,
    },
    {
        "CÓDIGO": " 3102",
        "DESCRIPCIÓN": "Anillo de compromiso  confeccionado en oro blanco de 18k.  La pieza cuenta con un total de trece diamantes. Se recomienda colocar un diamante central desde 0,30 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                               (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Con más Diamantes ó Laterales",
        "novios":"diamantes",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a3102,
    },
    {
        "CÓDIGO": "4027",
        "DESCRIPCIÓN": "Anillo de compromiso  confeccionado en oro amarillo de 18k.  La pieza cuenta con un total de trece diamantes. Se recomienda colocar un diamante central desde 0,30 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                               (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Con más Diamantes ó Laterales",
        "novios":"diamantes",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Amarillo",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a4027,
    },
    {
        "CÓDIGO": "4029",
        "DESCRIPCIÓN": "Anillo de compromiso  confeccionado en oro amarillo y detalles de oro blanco de 18k .  La pieza cuenta con un total de doce diamantes. Se recomienda colocar un diamante central desde 0,50 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                               (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Con más Diamantes ó Laterales",
        "novios":"diamantes",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Amarillo y Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a4029,
    },
    {
        "CÓDIGO": "3107",
        "DESCRIPCIÓN": "Anillo de compromiso  confeccionado en oro amarillo y detalles de oro blanco de 18k .  La pieza cuenta con un total de once diamantes. Se recomienda colocar un diamante central desde 0,30 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                               (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Con más Diamantes ó Laterales",
        "novios":"diamantes",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Amarillo y Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a3107,
    },
    {
        "CÓDIGO": "5007",
        "DESCRIPCIÓN": "Anillo de compromiso  confeccionado en oro amarillo y detalles de oro blanco de 18k .  La pieza cuenta con un total de veinticinco diamantes. Se recomienda colocar un diamante central desde 0,35 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                               (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Con más Diamantes ó Laterales",
        "novios":"diamantes",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Amarillo y Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a5007,
    },
    {
        "CÓDIGO": "3101",
        "DESCRIPCIÓN": "Anillo de compromiso  confeccionado en oro amarillo y detalles de oro blanco de 18k .  La pieza cuenta con un total de once diamantes. Se recomienda colocar un diamante central desde 0,30 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                               (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Con más Diamantes ó Laterales",
        "novios":"diamantes",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Amarillo y Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a3101,
    },
    {
        "CÓDIGO": "5011",
        "DESCRIPCIÓN": "Anillo de compromiso  confeccionado en oro amarillo y detalles de oro blanco de 18k .  La pieza cuenta con un total de tres diamantes. Se recomienda colocar un diamante central desde 0,30 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                               (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Con más Diamantes ó Laterales",
        "novios":"diamantes",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Amarillo y Blanco",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a5011,
    },
    {
        "CÓDIGO": "1801",
        "DESCRIPCIÓN": "Anillo de compromiso  confeccionado en oro amarillo de 18k .  La pieza cuenta con un total de trece diamantes. Se recomienda colocar un diamante central desde 0,20 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                               (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Con más Diamantes ó Laterales",
        "novios":"diamantes",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Amarillo",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a1801,
    },
    {
        "CÓDIGO": "4031",
        "DESCRIPCIÓN": "Anillo de compromiso  confeccionado en oro amarillo de 18k .  La pieza cuenta con un total de quince diamantes. Se recomienda colocar un diamante central desde 0,40 Carats. Diamantes de corte excelente y certificación GIA.                                                                                                                                               (Todas las joyas se pueden personalizar a tu gusto)",
        "Categoría": "Con más Diamantes ó Laterales",
        "novios":"diamantes",
        "Sección": "Novios/Anillo de Compromiso",
        "Color": "Oro-Amarillo",
        "TonalidadDeDiamante": "Blanco",
        "FormaDeDiamante": "Brillante-(Redondo)",
        "Otras Gemas": "",
        "imagen": a4031,
    }
]