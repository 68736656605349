import React from 'react';
import mapa from "../img/mapa.png"

class Contacto extends React.Component{
 
  render() {
	return(
        <div className="diamante pb-9"> 
  	<div className="white-background-text">
  	<form id="contact-form" method="get" className="real-white" action="https://diamanteperu.com/correo.php">
        <h1 className="raleway-semibold font-size-2em" >CONTACTO</h1>
        <p className="raleway-regular">Para cualquier consulta sobre alguna de nuestras joyas y diamantes, así como información de ventas y promociones, por favor contacte a uno de nuestros especialistas.</p>
  	    <div className="container-text-inputs">
          <div className="flex-input raleway-regular">
      	    <input type="text" required className="contact-input-size" id="name" name="nombre" placeholder="Nombre*" />
            <input type="text" required className="contact-input-size" id="lastname" name="apellido" placeholder="Apellido*"  />
          </div>
          <div className="flex-input raleway-regular">
            <input type="number" required className="contact-input-size" id="phone" name="celular" placeholder="Celular*"  />
      	    <input type="email" required className="contact-input-size" id="email" name="correo" aria-describedby="emailHelp" placeholder="Correo Electrónico*"  />
          </div>
          <textarea className="contact-input-size textarea raleway-regular"  rows="10" column="30"id="message"name="mensaje" required placeholder="Mensaje" />
  	    </div>
  	    <button type="submit" className="btn-flat black color-white border-none btn-contact text-size14 raleway-medium-italic">ENVIAR</button>
  	</form>
    <div className="container-more-contact raleway-medium">
                <p className="text-contact">Av. reducto 1135 - Miraflores-Lima,Perú</p>
                <p className="text-contact consultas"><i className="fas fa-envelope p-right-05 "></i>consultas@diamanteperu.com</p>
                <p className="text-contact consultas"><i className="fas fa-envelope p-right-05 "></i>info@diamanteperu.com</p>
                <p className="text-contact p-left-2"><i className="fa fa-whatsapp p-right-05"></i>949 218 855</p>
                <p className="text-contact p-left-2"><i className="fa fa-whatsapp p-right-05"></i>998 220 764</p>
                <p className="text-contact p-left-2"><i className="fas fa-phone-alt p-right-05"></i>01 445-6139</p>
            </div>
            <div className="center raleway-medium font-size-1em line-heigth-1">
              <p className="text-contact horariosAtencion center text-uppercase ">Atención únicamente por cita</p>
                <p className="text-contact horariosAtencion center ">Lunes, Miercoles y Viernes: 9:45 am – 2 pm y 3:00 pm - 6:15 pm</p>
                <p className="text-contact cierreRefrigerio center">(Cierre por refrigerio de 2pm – 3pm).</p>
            </div>
      <div className="mapa real-white real-white1">
        {/* <img src={mapa} alt="" className="w100"/> */}
        <div className="w100"><iframe width="100%" height="400" src="https://maps.google.com/maps?width=100%&height=600&hl=es&q=Av.%20Reducto%201135%20-%20Miraflores%20(Lima%2C%20Per%C3%BA)%20+(Diamante%20Per%C3%BA)&ie=UTF8&t=&z=16&iwloc=B&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"><a href="https://www.mapsdirections.info/calcular-ruta.html">Calcular Ruta</a></iframe></div><br/>
      </div>
  	</div>
      </div>
      
	);
  }
}

export default Contacto;