import React from 'react'

const C2A = ()=>{
return( 
<a href="https://wa.me/51949218855?text=Me%20gustaría%20tener%20información%20personalizada."
 className="float" >
<i className="fab fa-whatsapp"></i>
</a>);
}

export default C2A;